// src/App.js

import React, { useState, useEffect, useReducer, useCallback, useRef } from 'react';
import axios from 'axios';
import './App.css';
import { toast } from 'react-toastify';
import NetworkStatus from './components/NetworkStatus';
import LeftPanel from './components/LeftPanel';
import RightPanel from './components/RightPanel';
import Header from './components/Header';
import { outputReducer } from './components/OutputReducer';
import PrivacyPolicy from './pages/PrivacyPolicy';
import io from 'socket.io-client';
import AuthModal from './components/AuthModal';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import TermsOfService from './pages/TermsOfService';
import ReactModal from 'react-modal';
import { useAuth } from './contexts/AuthContext';
import { signOut } from 'firebase/auth';
import Pricing from './pages/Pricing';
import { auth, functions } from './components/firebaseConfig';
import PaymentSuccess from './pages/PaymentSuccess';
import TrainModal from './components/TrainModal';
import { httpsCallable } from 'firebase/functions';

ReactModal.setAppElement('#root');

// Custom hook to determine if the device is mobile
function useIsMobile() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.matchMedia('(max-width: 768px)').matches);
    };
    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);
    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);

  return isMobile;
}

// Button to collapse/show job panel on mobile devices
function CollapseButton({ isPanelCollapsed, togglePanel }) {
  return (
    <button
      className={`collapseButton ${isPanelCollapsed ? 'active' : ''}`}
      onClick={togglePanel}
      aria-label={isPanelCollapsed ? 'Show Jobs' : 'Hide Jobs'}
    >
      {isPanelCollapsed ? 'Show Jobs' : 'Hide Jobs'}
    </button>
  );
}

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state && location.state.fromPayment) {
      toast.success('Payment successful! Credits have been added to your account.');
    }
  }, [location, navigate]);

  const [isTrainModalOpen, setIsTrainModalOpen] = useState(false);

  const openTrainModal = useCallback(() => {
    setIsTrainModalOpen(true);
  }, []);

  const closeTrainModal = useCallback(() => {
    setIsTrainModalOpen(false);
  }, []);

  const isPricingPath = location.pathname === '/pricing';

  const closePricingModal = useCallback(() => {
    navigate('/', { replace: true });
  }, [navigate]);

  const { currentUser, isProUser, authLoading, isAuthModalOpen, closeAuthModal, openAuthModal } = useAuth();

  const isMobile = useIsMobile();
  const [mode, setMode] = useState('Standard');
  const [batchSize, setBatchSize] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPanelCollapsed, setIsPanelCollapsed] = useState(true);
  const [showPanel, setShowPanel] = useState(!isMobile);
  const [currentJobId, setCurrentJobId] = useState(null);
  const [genSocket, setGenSocket] = useState(null);
  const [trainSocket, setTrainSocket] = useState(null);
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [refreshLoras, setRefreshLoras] = useState(false);
  const [inputState, setInputState] = useState({
    clothing: '',
    scene: '',
    background: '',
    text: '',
    prompt: '',
    width: 512,
    height: 512,
    lora: 'None',
    instruction: null,
  });

  const [outputState, dispatchOutput] = useReducer(outputReducer, {
    imageUrl: '',
    previousImages: [],
    debugInfo: '',
    isLoading: false,
    queuePosition: null,
    totalJobs: null,
    jobId: null,
    promptId: null,
    jobs: [], // Unified jobs array
    isProUser: false,
  });

  const outputStateRef = useRef(outputState);
  useEffect(() => {
    outputStateRef.current = outputState;
  }, [outputState]);

  const handleNewImage = useCallback((newImageUrl) => {
    dispatchOutput({
      type: 'ADD_PREVIOUS_IMAGE',
      imageUrl: newImageUrl,
    });
  }, []);

  // Updated fetchUserImages function using uid
  const fetchUserImages = useCallback(
    async (uid) => {
      try {
        const response = await axios.get(
          `https://api.onlyjpegs.com/user-images/${encodeURIComponent(uid)}`,
          {
            withCredentials: true,
          }
        );

        if (response.data.images && Array.isArray(response.data.images)) {
          const reversedImages = [...response.data.images].reverse();

          dispatchOutput({
            type: 'SET_PREVIOUS_IMAGES',
            images: reversedImages,
          });
        } else {
          console.warn(
            `No images found for user with UID ${uid} or data format is incorrect.`
          );
        }
      } catch (error) {
        console.error(
          `Failed to fetch images for user with UID ${uid}:`,
          error
        );
      }
    },
    [dispatchOutput]
  );

  useEffect(() => {
    if (currentUser) {
      const uid = currentUser.uid;

      console.log('User logged in with UID:', uid);

      fetchUserImages(uid);
    } else {
      dispatchOutput({ type: 'CLEAR_PREVIOUS_IMAGES' });
    }
  }, [currentUser, fetchUserImages]);

  const handleLogin = useCallback(() => {
    if (isAuthModalOpen) return;
    openAuthModal();
  }, [isAuthModalOpen, openAuthModal]);

  const handleLogout = useCallback(() => {
    signOut(auth)
      .then(() => {
        console.log('User logged out successfully');
        toast.info('Logged out successfully.');
      })
      .catch((error) => {
        console.error('Error logging out: ', error.message);
        toast.error('Error logging out. Please try again.');
      });
  }, []);

  // Unified 'queue_update' listener for all jobs
  useEffect(() => {
    if (genSocket) {
      const handleQueueUpdate = (updatedQueue) => {
        const jobsToUpdate = updatedQueue.map(({ jobId, queuePosition }) => ({
          jobId,
          queuePosition,
        }));
        dispatchOutput({ type: 'UPDATE_JOBS', jobs: jobsToUpdate });
      };

      genSocket.on('queue_update', handleQueueUpdate);

      return () => {
        genSocket.off('queue_update', handleQueueUpdate);
      };
    }
  }, [genSocket]);

  // Unified 'job_update' listener for all jobs
  useEffect(() => {
    if (genSocket) {
      const handleJobUpdate = (data) => {
        const { jobId, status, image_url, queuePosition, jobType, jobName } = data;

        console.log('Received job_update for jobId:', jobId, data);

        // Map 'image_url' to 'imageUrl'
        const updatedData = {
          ...data,
          imageUrl: image_url || null,
        };

        // Check if the job exists in the current state
        const existingJob = outputStateRef.current.jobs.find(job => job.jobId === jobId);
        if (!existingJob) {
          console.warn(`Received job_update for unknown jobId: ${jobId}. Ignoring.`);
          return; // Ignore job updates for unknown jobs
        }

        dispatchOutput({ type: 'UPDATE_JOBS', jobs: [updatedData] });

        // Optionally, handle UI feedback based on status
        if (status === 'completed' && image_url) {
          if (jobType === 'generation') {
            toast.success(`Your image for job "${jobName}" is ready!`);
            handleNewImage(image_url);
          } else if (jobType === 'training') {
            toast.success(`Your training job "${jobName}" is completed!`);
          }
        }

        if (status === 'failed') {
          if (jobType === 'generation') {
            toast.error(`Job "${jobName}" failed.`);
          } else if (jobType === 'training') {
            toast.error(`Training job "${jobName}" failed.`);
          }
        }

        if (status === 'completed' || status === 'failed') {
          setCurrentJobId(null);
        }
      };

      genSocket.on('job_update', handleJobUpdate);

      return () => {
        genSocket.off('job_update', handleJobUpdate);
      };
    }
  }, [genSocket, handleNewImage]);

  // Unified 'job_update' listener for training jobs (if separate sockets are used)
  useEffect(() => {
    if (trainSocket) {
      const handleJobUpdate = (data) => {
        const { jobId, status, image_url, queuePosition, jobType, jobName } = data;
      
        console.log('Received job_update for jobId:', jobId, data);
      
        // Map 'image_url' to 'imageUrl'
        const updatedData = {
          ...data,
          imageUrl: image_url || null,
        };
      
        // Check if the job exists in the current state
        const existingJob = outputStateRef.current.jobs.find(job => job.jobId === jobId);
        if (!existingJob) {
          console.warn(`Received job_update for unknown jobId: ${jobId}. Adding it to jobs.`);
          // Dispatch a new action to add the job
          dispatchOutput({
            type: 'JOB_QUEUED',
            jobId,
            jobName: jobName || 'Unnamed Job',
            queuePosition: queuePosition || 0,
            jobType: jobType || 'generation',
          });
        }
      
        // Now, proceed to update the job
        dispatchOutput({ type: 'UPDATE_JOBS', jobs: [updatedData] });
      
        // Handle UI feedback based on status
        if (status === 'completed' && image_url) {
          if (jobType === 'generation') {
            toast.success(`Your image for job "${jobName}" is ready!`);
            handleNewImage(image_url);
          } else if (jobType === 'training') {
            toast.success(`Your training job "${jobName}" is completed!`);
          }
        }
      
        if (status === 'failed') {
          if (jobType === 'generation') {
            toast.error(`Job "${jobName}" failed.`);
          } else if (jobType === 'training') {
            toast.error(`Training job "${jobName}" failed.`);
          }
        }
      
        if (status === 'completed' || status === 'failed') {
          setCurrentJobId(null);
          setRefreshLoras(prev => !prev); // Toggle to trigger useEffect in LeftPanel
        }
      };

      trainSocket.on('job_update', handleJobUpdate);

      return () => {
        trainSocket.off('job_update', handleJobUpdate);
      };
    }
  }, [trainSocket, handleNewImage]);

  // Callable Function to Submit Image Generation Job with Credit Deduction
  const submitImageGenerationJob = httpsCallable(functions, 'submitImageGenerationJob');

  const handleSingleJobSubmit = useCallback(
    async (promptToUse) => {
      setIsSubmitting(true);

      try {
        if (!currentUser) {
          openAuthModal();
          return        
        }

        const username =
          currentUser.displayName ||
          currentUser.email ||
          currentUser.phoneNumber ||
          'anonymous';
        
        const uid = currentUser.uid;

        const result = await submitImageGenerationJob({
          prompt: promptToUse,
          width: inputState.width,
          height: inputState.height,
          lora: inputState.lora === 'None' ? null : inputState.lora,
          instruction: inputState.instruction || null,
          username: username,
          uid: uid
        });

        const response = result.data;

        const jobId = response.jobId;
        if (jobId) {
          const jobName = generateJobName(promptToUse, jobId, mode);
          dispatchOutput({
            type: 'JOB_QUEUED',
            jobId,
            jobName,
            queuePosition: response.queuePosition,
            jobType: 'generation',
          });

          toast.info(
            `Job "${jobName}" added to queue. Position: ${response.queuePosition}`
          );
          setShowPanel(true);
          setCurrentJobId(jobId);
        } else {
          toast.error('Failed to retrieve job ID from backend.');
        }
      } catch (error) {
        console.error('Error during job submission:', error);
        dispatchOutput({
          type: 'ERROR',
          jobId: null,
          message: error.message,
        });
        toast.error('Failed to submit job: ' + error.message);
      } finally {
        setIsSubmitting(false);
      }
    },
    [inputState, dispatchOutput, mode, currentUser, submitImageGenerationJob]
  );

  // Handle Input Changes
  const handleInputChange = useCallback((field, value) => {
    setInputState((prev) => ({ ...prev, [field]: value }));
  }, []);

  const generateJobName = useCallback((prompt, jobId, type = 'Job') => {
    const shortenedPrompt = prompt.split(' ').slice(0, 50).join('_');
    return `${shortenedPrompt}`;
  }, []);

  const handleBatchSubmit = useCallback(async () => {
    setIsSubmitting(true);
    dispatchOutput({ type: 'SUBMIT' });

    try {
      const defaultPrompt = 'Empty Prompt Entered. Generating random image.';

      if (isProUser) {
        const promptsArray = (inputState.prompt || defaultPrompt)
          .split('\n')
          .map((line) => line.trim())
          .filter((line) => line !== '');

        for (const prompt of promptsArray) {
          for (let i = 0; i < batchSize; i++) {
            await handleSingleJobSubmit(prompt);
          }
        }
      } else {
        for (let i = 0; i < batchSize; i++) {
          console.log(
            'Submitting standard prompt:',
            inputState.prompt || defaultPrompt
          );
          await handleSingleJobSubmit(inputState.prompt || defaultPrompt);
        }
      }
    } catch (error) {
      console.error('Error submitting job:', error);
      toast.error('An error occurred during job submission.');
    } finally {
      setIsSubmitting(false);
    }
  }, [batchSize, handleSingleJobSubmit, inputState.prompt, dispatchOutput, isProUser]);

  const togglePanel = useCallback(() => {
    if (isMobile) {
      setIsPanelCollapsed((prev) => !prev);
    }
  }, [isMobile]);

  // Initialize WebSockets for job updates (if still needed)
  useEffect(() => {
    console.log('Connecting to Image Generation WebSocket...');
    const newGenSocket = io('https://api.onlyjpegs.com', {
      withCredentials: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      timeout: 20000,
      transports: ['websocket'],
    });

    const handleGenConnect = () => {
      console.log('Connected to Image Generation WebSocket.');
      const clientSid = newGenSocket.id;
      newGenSocket.emit('join_room', { room: clientSid });
    };

    const handleGenDisconnect = () => {
      console.log('Disconnected from Image Generation WebSocket.');
    };

    const handleGenConnectError = (error) => {
      console.error('Image Generation Connection Error:', error);
      toast.error('WebSocket connection error for Image Generation. Reconnecting...');
    };

    const handleGenReconnect = (attemptNumber) => {
      console.info(
        `Reconnected to Image Generation server after ${attemptNumber} attempts.`
      );
      toast.success('Reconnected to Image Generation WebSocket server.');
    };

    const handleGenReconnectFailed = () => {
      console.error('Failed to reconnect to Image Generation WebSocket server.');
      toast.error('Failed to reconnect to Image Generation WebSocket server.');
    };

    newGenSocket.on('connect', handleGenConnect);
    newGenSocket.on('disconnect', handleGenDisconnect);
    newGenSocket.on('connect_error', handleGenConnectError);
    newGenSocket.on('reconnect', handleGenReconnect);
    newGenSocket.on('reconnect_failed', handleGenReconnectFailed);

    setGenSocket(newGenSocket);

    return () => {
      newGenSocket.off('connect', handleGenConnect);
      newGenSocket.off('disconnect', handleGenDisconnect);
      newGenSocket.off('connect_error', handleGenConnectError);
      newGenSocket.off('reconnect', handleGenReconnect);
      newGenSocket.off('reconnect_failed', handleGenReconnectFailed);
      newGenSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    console.log('Connecting to Training WebSocket...');
    const newTrainSocket = io('https://api.blerst.com', {
      withCredentials: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      timeout: 20000,
      transports: ['websocket'],
    });

    const handleTrainConnect = () => {
      console.log('Connected to Training WebSocket.');
      const clientSid = newTrainSocket.id;
      newTrainSocket.emit('join_room', { room: clientSid });
    };

    const handleTrainDisconnect = () => {
      console.log('Disconnected from Training WebSocket.');
    };

    const handleTrainConnectError = (error) => {
      console.error('Training Connection Error:', error);
      toast.error('WebSocket connection error for Training. Reconnecting...');
    };

    const handleTrainReconnect = (attemptNumber) => {
      console.info(
        `Reconnected to Training server after ${attemptNumber} attempts.`
      );
      toast.success('Reconnected to Training WebSocket server.');
    };

    const handleTrainReconnectFailed = () => {
      console.error('Failed to reconnect to Training WebSocket server.');
      toast.error('Failed to reconnect to Training WebSocket server.');
    };

    newTrainSocket.on('connect', handleTrainConnect);
    newTrainSocket.on('disconnect', handleTrainDisconnect);
    newTrainSocket.on('connect_error', handleTrainConnectError);
    newTrainSocket.on('reconnect', handleTrainReconnect);
    newTrainSocket.on('reconnect_failed', handleTrainReconnectFailed);

    setTrainSocket(newTrainSocket);

    return () => {
      newTrainSocket.off('connect', handleTrainConnect);
      newTrainSocket.off('disconnect', handleTrainDisconnect);
      newTrainSocket.off('connect_error', handleTrainConnectError);
      newTrainSocket.off('reconnect', handleTrainReconnect);
      newTrainSocket.off('reconnect_failed', handleTrainReconnectFailed);
      newTrainSocket.disconnect();
    };
  }, []);

  if (authLoading) {
    return (
      <div className="loadingContainer">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="appContainer">
      <Header
        handleLogin={handleLogin}
        handleLogout={handleLogout}
        isAuthModalOpen={isAuthModalOpen}
        openTrainModal={openTrainModal}
      />
      <Routes>
        <Route
          path="/"
          element={
            <div className="mainContent">
              <NetworkStatus />
              <LeftPanel
                mode={mode}
                setMode={setMode}
                inputState={inputState}
                handleInputChange={handleInputChange}
                handleSubmit={handleBatchSubmit}
                batchSize={batchSize}
                setBatchSize={setBatchSize}
                outputState={outputState}
                isSubmitting={isSubmitting}
                isProUser={isProUser}
                refreshLoras={refreshLoras} // Pass the refreshLoras prop
              />
              {isMobile && (
                <CollapseButton
                  isPanelCollapsed={isPanelCollapsed}
                  togglePanel={togglePanel}
                  className={modalOpen ? 'modalOpen' : ''}
                />
              )}
              <div
                className={`rightPanelContainer ${
                  isPanelCollapsed && isMobile ? 'collapsed' : ''
                }`}
              >
                <RightPanel
                  outputState={outputState}
                  dispatchOutput={dispatchOutput}
                  jobs={outputState.jobs}
                  modalOpen={modalOpen}
                  setModalOpen={setModalOpen}
                />
              </div>
            </div>
          }
        />
        <Route path="/payment-success" element={<PaymentSuccess />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
      </Routes>

      <Pricing isOpen={isPricingPath} onRequestClose={closePricingModal} />
      <TermsOfService isOpen={isTermsOpen} onRequestClose={() => setIsTermsOpen(false)} />
      <PrivacyPolicy isOpen={isPrivacyOpen} onRequestClose={() => setIsPrivacyOpen(false)} />

      <AuthModal
        isOpen={isAuthModalOpen}
        onRequestClose={closeAuthModal}
      />

      <TrainModal
        isOpen={isTrainModalOpen}
        onRequestClose={closeTrainModal}
      />
    </div>
  );
}

export default App;
