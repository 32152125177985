import React, { useCallback, useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import './styles/TrainModal.css'; // Ensure this path is correct
import { useAuth } from '../contexts/AuthContext';
import { toast } from 'react-toastify';
import { FaTrashAlt, FaPlus } from 'react-icons/fa'; // Added FaPlus for the upload icon
import axios from 'axios';
import { functions } from '../components/firebaseConfig'; // Import initialized functions
import { httpsCallable } from 'firebase/functions';

function TrainModal({ isOpen, onRequestClose }) {
  const { currentUser } = useAuth();

  // Initialize the callable function
  const submitTrainingJob = httpsCallable(functions, 'submitTrainingJob');

  // State Variables
  const [images, setImages] = useState([]);
  const [modelName, setModelName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modelNameError, setModelNameError] = useState(''); // New state for model name errors

  // Handle Modal Close
  const handleRequestClose = useCallback(() => {
    if (!isSubmitting) {
      onRequestClose();
      // Reset form
      images.forEach((image) => URL.revokeObjectURL(image.preview));
      setImages([]);
      setModelName('');
      setModelNameError('');
    }
  }, [isSubmitting, onRequestClose, images]);

  // Handle Image Selection
  const handleImageChange = useCallback((e) => {
    const files = Array.from(e.target.files);
    if (files.length === 0) return;

    // Check if adding these files exceeds the limit
    if (images.length + files.length > 10) {
      toast.error('You can upload a maximum of 10 images.');
      return;
    }

    // Filter out non-image files
    const validImages = files.filter((file) => file.type.startsWith('image/'));
    if (validImages.length !== files.length) {
      toast.warn('Some files were ignored because they are not images.');
    }

    // Map files to include preview URLs
    const imagesWithPreview = validImages.map((file) => ({
      file,
      preview: URL.createObjectURL(file),
      id: `${file.name}-${file.size}-${file.lastModified}`, // Unique identifier
    }));

    setImages((prevImages) => [...prevImages, ...imagesWithPreview]);
    e.target.value = null; // Reset the input
  }, [images.length]);

  // Handle Image Removal
  const handleRemoveImage = useCallback((id) => {
    setImages((prevImages) => {
      const updatedImages = prevImages.filter((image) => image.id !== id);
      const removedImage = prevImages.find((image) => image.id === id);
      if (removedImage) {
        URL.revokeObjectURL(removedImage.preview);
      }
      return updatedImages;
    });
  }, []);

  // Handle Form Submission
  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      // Reset previous errors
      setModelNameError('');

      // Additional client-side validation
      if (images.length < 5) {
        toast.error('Please upload at least 5 images.');
        return;
      }

      if (modelName.trim() === '') {
        toast.error('Model name cannot be empty.');
        return;
      }

      setIsSubmitting(true);

      try {
        // Prepare form data for backend upload
        const formData = new FormData();
        formData.append('username', currentUser.uid); // Assuming UID as username
        formData.append('modelName', modelName.trim());
        // Append each image file
        images.forEach((image, index) => {
          formData.append('images', image.file);
        });

        // Upload images to the backend
        const uploadResponse = await axios.post(
          'https://api.blerst.com/upload-dataset', // Corrected endpoint
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            withCredentials: true, // If your backend requires credentials
          }
        );

        // Get the datasetPath from the response
        const { datasetPath } = uploadResponse.data;

        // Call Firebase Callable Function to submit training job
        const result = await submitTrainingJob({
          modelName: modelName.trim(),
          datasetPath: datasetPath, // Path returned from backend
          username: currentUser.uid, // Or any other identifier
        });

        const firebaseResponse = result.data;

        const firebaseJobId = firebaseResponse.jobId;
        const queuePosition = firebaseResponse.queuePosition;

        if (firebaseJobId) {
          toast.success('Model customization submitted successfully!');
          handleRequestClose(); // Reset and close modal
          // Optionally, emit or store the jobId for tracking
        } else {
          toast.error('Failed to submit model customization.');
        }
      } catch (error) {
        console.error('Error submitting model customization:', error);

        // Handle specific errors
        if (error.response) {
          const { status, data } = error.response;

          if (status === 400) {
            if (data.status === 'Model name already exists') {
              setModelNameError('This model name is already taken. Please choose a different name.');
              toast.error('Model name already exists. Please choose a different name.');
            } else if (data.status === 'No images uploaded') {
              toast.error('No images were uploaded. Please select images to proceed.');
            } else if (data.status === 'Missing \'username\' or \'modelName\' in the request') {
              toast.error('Missing required fields. Please try again.');
            } else {
              toast.error(data.status || 'An error occurred while uploading the dataset.');
            }
          } else {
            toast.error(data.status || 'An unexpected error occurred.');
          }
        } else if (error.code === 'already-exists') {
          // Handle Firebase Callable Function specific error if needed
          setModelNameError('This model name is already taken. Please choose a different name.');
          toast.error('Model name already exists. Please choose a different name.');
        } else {
          const errorMessage =
            error?.message ||
            'An error occurred while submitting your model customization.';
          toast.error('Failed to submit training job: ' + errorMessage);
        }
      } finally {
        setIsSubmitting(false);
      }
    },
    [images, modelName, currentUser, submitTrainingJob, handleRequestClose]
  );

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={handleRequestClose}
      className="trainModal"
      overlayClassName="trainModalOverlay"
      shouldCloseOnOverlayClick={!isSubmitting}
      shouldCloseOnEsc={!isSubmitting}
      ariaHideApp={false} // Add this if you encounter accessibility warnings
    >
      <div className="trainModalHeader">
        <h2>Train AI on your images</h2>
        <button
          className="closeModalButton"
          onClick={handleRequestClose}
          disabled={isSubmitting}
          aria-label="Close Model Customization Modal"
        >
          &times;
        </button>
      </div>
      <div className="modalDescription">
        <p>
          Create your own personalized model based on your selfies or your unique style of art.
        </p>
      </div>
      <form onSubmit={handleSubmit} className="trainModalForm">
        <div className="formGroup">
          <label htmlFor="modelName">Model Name</label>
          <input
            type="text"
            id="modelName"
            value={modelName}
            onChange={(e) => {
              setModelName(e.target.value);
              setModelNameError(''); // Reset error on change
            }}
            placeholder="Enter a unique name for your model"
            required
            disabled={isSubmitting}
            className={modelNameError ? 'inputError' : ''}
            aria-invalid={!!modelNameError}
            aria-describedby="modelNameError"
          />
          {modelNameError && (
            <small id="modelNameError" className="errorText">
              {modelNameError}
            </small>
          )}
        </div>
        <div className="formGroup">
          <label>Upload 5-10 images. We recommend 10 images.</label>
          {!isSubmitting && images.length < 10 && (
            <div className="fileUploadContainer">
              <label
                htmlFor="images"
                className={`fileUploadLabel ${
                  isSubmitting || images.length >= 10 ? 'disabled' : ''
                }`}
                aria-label="Add Images for Model Customization"
              >
                <FaPlus className="uploadIcon" />
                <span>Add Images</span>
              </label>
              <input
                type="file"
                id="images"
                accept="image/*"
                multiple
                onChange={handleImageChange}
                disabled={isSubmitting || images.length >= 10}
                className="fileInput"
              />
            </div>
          )}
          <small>
            {images.length < 5
              ? `Minimum 5 images. You have selected ${images.length}.`
              : images.length < 10
              ? `You can upload ${10 - images.length} more image(s).`
              : 'Maximum of 10 images reached.'}
          </small>
          <div className="imagePreviewContainer">
            {images.length > 0 ? (
              images.map((image) => (
                <div key={image.id} className="imageWrapper">
                  <img
                    src={image.preview}
                    alt={`Upload Preview ${image.id}`}
                    className="imagePreview"
                  />
                  <button
                    type="button"
                    className="removeImageButton"
                    onClick={() => handleRemoveImage(image.id)}
                    disabled={isSubmitting}
                    title="Remove Image"
                    aria-label="Remove selected image"
                  >
                    <FaTrashAlt />
                  </button>
                </div>
              ))
            ) : (
              <p className="noImagesText">No images selected. Click the plus icon to add images.</p>
            )}
          </div>
        </div>
        <div className="bestPractices">
          <h3>Tips for Choosing Images:</h3>
          <ul>
            <li>Use high-resolution images with good lighting.</li>
            <li>Avoid cluttered backgrounds to focus on the subject.</li>
            <li>Ensure your subject is clearly visible and centered.</li>
            <li>Use different sizes and orientations.</li>
            <li>Use different poses or angles.</li>
          </ul>
        </div>
        <div className="infoText">
          <p>
            Your model will take approximately <strong>2 hours</strong> to create.
            It may take longer if there is a queue. Your models will be available for you to use when signed in.
          </p>
        </div>
        <button
          type="submit"
          className="trainButton"
          disabled={isSubmitting || images.length < 5 || modelName.trim() === ''}
        >
          {isSubmitting ? 'Submitting...' : 'Customize Model'}
        </button>
      </form>
    </ReactModal>
  );
}

export default TrainModal;
