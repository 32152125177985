// src/components/Header.js

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './styles/Header.module.css';
import { toast } from 'react-toastify';
import { useAuth } from '../contexts/AuthContext'; // Adjust the path as necessary
import Pricing from '../pages/Pricing';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import TermsOfService from '../pages/TermsOfService';

const Header = ({ handleLogin, handleLogout, isAuthModalOpen }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isPricingModalOpen, setIsPricingModalOpen] = useState(false);
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const { currentUser, isProUser, loading } = useAuth(); // Destructure loading

  const openPricingModal = () => {
    setIsPricingModalOpen(true);
  };
  
  const closePricingModal = () => {
    setIsPricingModalOpen(false);
  };

  const openPrivacyModal = () => {
    setIsPrivacyModalOpen(true);
  };
  
  const closePrivacyModal = () => {
    setIsPrivacyModalOpen(false);
  };

  const openTermsModal = () => {
    setIsTermsModalOpen(true);
  };
  
  const closeTermsModal = () => {
    setIsTermsModalOpen(false);
  };

  // Toggle Dropdown Menu
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
    console.log(`Header: Dropdown visibility set to ${!dropdownVisible}`);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        dropdownVisible &&
        !event.target.closest(`.${styles.dropdownMenu}`) &&
        !event.target.closest(`.${styles.hamburgerMenu}`)
      ) {
        setDropdownVisible(false);
        console.log('Header: Dropdown closed due to outside click');
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [dropdownVisible]);

  // Log the authentication and user data
  useEffect(() => {
    console.log('Header: Current User:', currentUser);
    console.log('Header: Is Pro User:', isProUser);
  }, [currentUser, isProUser]);

  if (loading) {
    // Optionally, render a loading indicator or return null to avoid rendering prematurely
    return (
      <div className={styles.header}>
        <div className={styles.loading}>Loading...</div>
      </div>
    );
  }

  return (
    <div className={styles.header}>
      <a
        href="http://onlyjpegs.com/"
        className={styles.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className={styles.title}>AI Image Generator</div>
      </a>
      <div className={styles.authControls}>
        <div className={styles.authContainer}>
          {currentUser ? (
            <>
              <span className={styles.welcomeText}>
                {currentUser.displayName
                  ? `Welcome, ${currentUser.displayName}!`
                  : currentUser.email
                  ? `Welcome, ${currentUser.email}.`
                  : currentUser.phoneNumber
                  ? `Welcome, ${currentUser.phoneNumber}.`
                  : 'Welcome'}
              </span>
              {/* Display Credits */}
              <span className={styles.creditsText}>
                Credits: {currentUser.credits !== undefined ? currentUser.credits : '0'}
              </span>
              <button onClick={handleLogout} className={styles.button}>
                Logout
              </button>
            </>
          ) : (
            <>
              <button
                onClick={handleLogin}
                className={styles.button}
                disabled={isAuthModalOpen}
              >
                {isAuthModalOpen ? 'Signing In...' : 'Login'}
              </button>
            </>
          )}
        </div>
        <div className={styles.hamburgerMenu} onClick={toggleDropdown}>
          &#9776; {/* Hamburger icon */}
        </div>
      </div>

      {/* Dropdown Menu */}
      <div
        className={`${styles.dropdownMenu} ${
          dropdownVisible ? styles.show : ''
        }`}
      >
        <button
          to="/"
          className={styles.dropdownItem}
          onClick={() => {
            setDropdownVisible(false);
            console.log('Header: Navigated to Home');
          }}
        >
          Home
        </button>

        <button
          className={styles.dropdownItem}
          onClick={() => {
            setDropdownVisible(false);
            openPricingModal();
          }}
        >
          Pricing
        </button>

        <button
          className={styles.dropdownItem}
          onClick={() => {
            setDropdownVisible(false);
            openPrivacyModal();
          }}
        >
          Privacy Policy
        </button>

        <button
          className={styles.dropdownItem}
          onClick={() => {
            setDropdownVisible(false);
            openTermsModal();
          }}
        >
          Terms of Service
        </button>
      </div>

      {/* Pricing Modal */}
      <Pricing
        isOpen={isPricingModalOpen}
        onRequestClose={closePricingModal}
      />

      {/* Privacy Policy Modal */}
      <PrivacyPolicy
        isOpen={isPrivacyModalOpen}
        onRequestClose={closePrivacyModal}
      />

      {/* Terms of Service Modal */}
      <TermsOfService
        isOpen={isTermsModalOpen}
        onRequestClose={closeTermsModal}
      />

      {/* Handle Errors */}
      {/* Removed Firestore-related error handling as it's no longer needed */}
    </div>
  );
};

export default Header;
