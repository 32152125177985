// src/components/JobsSection.js

import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import styles from '../components/styles/RightPanel.module.css';
import { toast } from 'react-toastify';

const JobsSection = ({ jobs, saveImage, openModal }) => {
  // Debugging: Log the jobs array to verify it's being received correctly
  useEffect(() => {
    console.log('JobsSection received jobs:', jobs);
  }, [jobs]);

  return (
    <div className={styles.jobsSection}>
      <h2>My Jobs</h2>
      <div className={styles.jobsFeed}>
        {jobs.slice().reverse().map((job) => {
          // Ensure job has a valid status
          const status = job.status?.toLowerCase() || 'unknown';
          // Determine if queuePosition should be displayed
          const queuePositionText =
            job.queuePosition !== null && job.queuePosition !== 0
              ? `Queue Position: ${job.queuePosition}`
              : null;

          return (
            <div
              key={job.jobId}
              className={`${styles.jobCard} ${
                status === 'completed'
                  ? styles.completed
                  : status === 'processing'
                  ? styles.processing
                  : styles.queued
              }`}
            >
              {job.jobType === 'generation' ? (
                // Rendering for Generation Jobs
                <>
                  <p>
                    <strong>Job:</strong> {job.jobName || 'Unnamed Job'}
                  </p>
                  {status !== 'completed' && (
                    <p>
                      <strong>Status:</strong> {capitalizeFirstLetter(status)}
                    </p>
                  )}
                  {queuePositionText && (
                    <p>
                      <strong>{queuePositionText}</strong>
                    </p>
                  )}
                  {job.imageUrl && (
                    <div className={styles.jobImageContainer}>
                      <img
                        src={job.imageUrl}
                        alt="Generated"
                        className={styles.jobImage}
                        onClick={() => openModal(job.imageUrl)}
                      />
                      <button
                        className={styles.saveButtonOverlay}
                        onClick={() => saveImage(job.imageUrl)}
                        aria-label="Save Image"
                      >
                        <FontAwesomeIcon icon={faDownload} />
                      </button>
                      {/* Optional: Add a share button for generation jobs */}
                      <button
                        className={styles.shareButtonOverlay}
                        onClick={() => {
                          navigator.clipboard.writeText(job.imageUrl);
                          toast.success('Image URL copied to clipboard!');
                        }}
                        aria-label="Share Image URL"
                      >
                        <FontAwesomeIcon icon={faShareAlt} />
                      </button>
                    </div>
                  )}
                </>
              ) : (
                // Rendering for Training Jobs
                <>
                  <p>
                    <strong>Training Job:</strong> {job.jobName || 'Unnamed Model'}
                  </p>
                  {status !== 'completed' && (
                    <p>
                      <strong>Status:</strong> {capitalizeFirstLetter(status)}
                    </p>
                  )}
                  {queuePositionText && (
                    <p>
                      <strong>{queuePositionText}</strong>
                      <p>
                        *Training jobs take 1.5 hours each.
                      </p>
                    </p>
                  )}
                  {status === 'completed' && (
                    <div className={styles.trainingCompleted}>
                      <p >
                        <strong className={styles.trainingCompletedText}>Model Created.</strong> 
                      </p>
                    </div>
                  )}
                  {status === 'failed' && (
                    <div className={styles.errorMessage}>
                      <p>Training job failed. Please try again.</p>
                      <button
                        className={styles.retryButton}
                        onClick={() => {
                          // Placeholder for retrying the training job
                          toast.info('Retry feature is coming soon!');
                        }}
                        aria-label="Retry Training Job"
                      >
                        Retry
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

// Helper function to capitalize the first letter of a string
const capitalizeFirstLetter = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export default JobsSection;
