// src/pages/PaymentSuccess.js

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { useAuth } from '../contexts/AuthContext';
import { toast } from 'react-toastify';

const PaymentSuccess = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    if (currentUser) {
      // Show the success toast
      toast.success('Payment successful! Credits have been added to your account.');

      // Redirect to home after a short delay to allow the toast to be seen
      const timer = setTimeout(() => {
        navigate('/', { replace: true });
      }, 1000); // 3 seconds delay

      // Cleanup the timer if the component unmounts before the timeout
      return () => clearTimeout(timer);
    } else {
      // If there's no current user, redirect to home immediately
      navigate('/', { replace: true });
    }
  }, [currentUser, navigate]);

  return null; // Render nothing
};

export default PaymentSuccess;
