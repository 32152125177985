// src/components/LeftPanel.js

import React, { useState, useEffect, useCallback } from 'react';
import ModeSwitch from '../components/ModeSwitch';
import StandardInputs from '../components/StandardInputs';
import SliderInput from '../components/SliderInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import styles from '../components/styles/LeftPanel.module.css';
import prompts from './prompts.json'; // Adjust the path accordingly
import TrainModal from './TrainModal'; // Import the TrainModal component
import { useAuth } from '../contexts/AuthContext'; // Import AuthContext
import { toast } from 'react-toastify'; // Ensure react-toastify is imported

const LeftPanel = ({
  mode,
  setMode,
  outputState,
  handleInputChange,
  handleSubmit,
  inputState,
  isSubmitting,
  batchSize,
  setBatchSize,
  refreshLoras, // Receive the refreshLoras prop
}) => {
  const [loras, setLoras] = useState([]);
  const [isPromptActive, setIsPromptActive] = useState(false);
  const [allPrompts, setAllPrompts] = useState([]);
  const [isTrainModalOpen, setIsTrainModalOpen] = useState(false); // State to control TrainModal

  const { currentUser, openAuthModal } = useAuth(); // Get currentUser and openAuthModal from AuthContext

  // Function to fetch LoRAs
  const fetchLoras = useCallback(async () => {
    try {
      if (!currentUser) {
        setLoras([]); // Clear LoRAs if user is not logged in
        return;
      }

      const username = encodeURIComponent(
        currentUser.uid || 'anonymous'
      );
      const response = await fetch(`https://api.blerst.com/get-loras/${username}`);
      const data = await response.json();
      if (response.ok) {
        setLoras(data.loras);
      } else {
        console.error('Error fetching LoRAs:', data.error);
      }
    } catch (error) {
      console.error('Error fetching LoRAs:', error);
    }
  }, [currentUser]);

  // Fetch LoRAs on component mount and when refreshLoras changes
  useEffect(() => {
    fetchLoras();
  }, [fetchLoras, refreshLoras]); // Add refreshLoras to dependency array

  // Load prompts from the imported JSON
  useEffect(() => {
    setAllPrompts(prompts);
  }, []);

  // Handle opening and closing of TrainModal
  const openTrainYourOwn = useCallback(() => {
    if (currentUser) {
      setIsTrainModalOpen(true);
    } else {
      openAuthModal(); // Trigger the global AuthModal
    }
  }, [currentUser, openAuthModal]);

  const closeTrainModal = useCallback(() => {
    setIsTrainModalOpen(false);
  }, []);

  // Handlers for incrementing and decrementing batchSize
  const incrementBatchSize = () => {
    setBatchSize((prev) => {
      const newValue = prev + 1;
      return newValue > 10 ? 10 : newValue;
    });
  };

  const decrementBatchSize = () => {
    setBatchSize((prev) => {
      const newValue = prev - 1;
      return newValue < 1 ? 1 : newValue;
    });
  };

  // Handle direct input changes and enforce bounds
  const handleBatchSizeChange = (e) => {
    const value = Number(e.target.value);
    if (isNaN(value)) return;
    if (value < 1) {
      setBatchSize(1);
    } else if (value > 10) {
      setBatchSize(10);
    } else {
      setBatchSize(value);
    }
  };

  // Function to select a random prompt
  const selectRandomPrompt = () => {
    if (allPrompts.length === 0) return;
    const randomIndex = Math.floor(Math.random() * allPrompts.length);
    const randomPrompt = allPrompts[randomIndex].prompt;
    handleInputChange('prompt', randomPrompt);
    // Optional: Provide feedback to the user
    // toast.success('Random prompt loaded!');
  };

  return (
    <div className={styles.leftPanel}>
      <div className={styles.header}>onlyjpegs</div>

      <StandardInputs
        prompt={inputState.prompt}
        setPrompt={(value) => handleInputChange('prompt', value)}
        lora={inputState.lora}
        setLora={(value) => handleInputChange('lora', value)}
        isLoading={isSubmitting}
        onFocus={() => setIsPromptActive(true)}
        onBlur={() => setIsPromptActive(false)}
        isPromptActive={isPromptActive}
      />

      <div className={styles.randomPromptContainer}>
      {/* Random Prompt Button */}
      <button
        onClick={() => {
          if (!isSubmitting) {
            const randomIndex = Math.floor(Math.random() * allPrompts.length);
            const randomPrompt = allPrompts[randomIndex]?.prompt || '';
            handleInputChange('prompt', randomPrompt);
          }
        }}
        className={styles.randomPromptButton}
        disabled={isSubmitting || allPrompts.length === 0}
      >
        Random Prompt
      </button>
      </div>

      {/* <ModeSwitch
        mode={mode}
        setMode={setMode}
        isLoading={isSubmitting}
        className={styles.loraDropdown}
      /> */}

      <label className={styles.inputLabel}>Image Size</label>

      <div className={styles.quickSelectContainer}>
        <button
          className={`${styles.quickSelectButton} ${
            inputState.width === 512 && inputState.height === 512 ? styles.active : ''
          }`}
          onClick={() => handleInputChange('width', 512) || handleInputChange('height', 512)}
          disabled={isSubmitting}
        >
          Small<br />512x512
        </button>
        <button
          className={`${styles.quickSelectButton} ${
            inputState.width === 768 && inputState.height === 1024 ? styles.active : ''
          }`}
          onClick={() => handleInputChange('width', 768) || handleInputChange('height', 1024)}
          disabled={isSubmitting}
        >
          Portrait<br />768x1024
        </button>
        <button
          className={`${styles.quickSelectButton} ${
            inputState.width === 1024 && inputState.height === 1024 ? styles.active : ''
          }`}
          onClick={() => handleInputChange('width', 1024) || handleInputChange('height', 1024)}
          disabled={isSubmitting}
        >
          Square<br />1024x1024
        </button>
        <button
          className={`${styles.quickSelectButton} ${
            inputState.width === 1024 && inputState.height === 768 ? styles.active : ''
          }`}
          onClick={() => handleInputChange('width', 1024) || handleInputChange('height', 768)}
          disabled={isSubmitting}
        >
          Landscape<br />1024x768
        </button>
      </div>

      <SliderInput
        label="Width"
        value={inputState.width}
        onChange={(value) => handleInputChange('width', value)}
        min={512}
        max={1368}
        step={256}
        isLoading={isSubmitting}
      />
      <SliderInput
        label="Height"
        value={inputState.height}
        onChange={(value) => handleInputChange('height', value)}
        min={512}
        max={1368}
        step={256}
        isLoading={isSubmitting}
      />

      {/* Styles Dropdown */}
      <label className={styles.inputLabel}>Models/Styles</label>
      <div className={styles.stylesContainer}>
        <select
          value={inputState.lora || ''}
          onChange={(e) => handleInputChange('lora', e.target.value || null)}
          className={styles.dropdown}
          disabled={isSubmitting}
        >
          <option value="">None</option>
          {loras.map((loraFile, index) => (
            <option key={index} value={loraFile}>
              {loraFile.replace('.safetensors', '').toUpperCase()}
            </option>
          ))}
        </select>
      </div>
      
      {/* Train Your Own Button */}
      <button
        onClick={openTrainYourOwn}
        className={styles.trainYourOwnButton}
        disabled={isSubmitting}
      >
        Train Your Own
      </button>

      <label className={styles.inputLabel}>Number of Images</label>
      <div className={styles.batchInputContainer}>
        {batchSize > 1 && (
          <button
            onClick={decrementBatchSize}
            className={`${styles.batchButton} ${styles.decrementButton}`}
            disabled={isSubmitting}
            aria-label="Decrease number of images"
          >
            <FontAwesomeIcon icon={faArrowDown} />
          </button>
        )}
        <input
          type="number"
          value={batchSize}
          onChange={handleBatchSizeChange}
          min={1}
          max={10}
          className={styles.batchInput}
          disabled={isSubmitting}
        />
        {batchSize < 10 && (
          <button
            onClick={incrementBatchSize}
            className={`${styles.batchButton} ${styles.incrementButton}`}
            disabled={isSubmitting}
            aria-label="Increase number of images"
          >
            <FontAwesomeIcon icon={faArrowUp} />
          </button>
        )}
      </div>

      <button
        onClick={handleSubmit}
        disabled={isSubmitting}
        className={styles.button}
      >
        {isSubmitting ? 'Submitting...' : 'Generate'}
      </button>

      {/* Train Modal */}
      <TrainModal isOpen={isTrainModalOpen} onRequestClose={closeTrainModal} />
    </div>
  );
};

export default LeftPanel;
