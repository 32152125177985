// src/components/OutputReducer.js

export const outputReducer = (state, action) => {
  const JobStatus = {
    QUEUED: 'queued',
    PROCESSING: 'processing',
    COMPLETED: 'completed',
    FAILED: 'failed',
  };

  // Helper function to determine if any jobs are still loading
  const calculateIsLoading = (jobs) => {
    return jobs.some(
      (job) => job.status === JobStatus.QUEUED || job.status === JobStatus.PROCESSING
    );
  };

  switch (action.type) {

    // Handle user Pro status updates
    case 'SET_USER_PRO_STATUS':
      return {
        ...state,
        isProUser: action.isProUser,
        debugInfo: `User Pro status updated to ${action.isProUser}`,
      };

    // Handle job submission initiation
    case 'SUBMIT':
      return {
        ...state,
        isLoading: true,
        debugInfo: 'Submitting job to queue...',
      };

    case 'JOB_QUEUED':
      const newJob = {
        jobId: action.jobId,
        jobName: action.jobName,
        queuePosition: action.queuePosition,
        status: JobStatus.QUEUED,
        imageUrl: null,
        promptId: null,
        isProcessing: false,
        jobType: action.jobType || 'generation',
      };
      console.log(`JOB_QUEUED: Adding job ${action.jobId} - "${action.jobName}" at position ${action.queuePosition}`);
      return {
        ...state,
        jobs: [...state.jobs, newJob],
        isLoading: true,
        debugInfo: `Job "${action.jobName}" queued at position ${action.queuePosition}.`,
      };

    // Handle updates to existing jobs
    case 'UPDATE_JOBS':
      console.log('Action: UPDATE_JOBS', action.jobs);

      let updatedJobs = state.jobs.map((currentJob) => {
        const updatedJob = action.jobs.find((job) => job.jobId === currentJob.jobId);
        if (updatedJob) {
          const hasChanged =
            currentJob.status !== updatedJob.status ||
            currentJob.queuePosition !== updatedJob.queuePosition ||
            currentJob.imageUrl !== updatedJob.imageUrl ||
            currentJob.isProcessing !== updatedJob.isProcessing;
          if (hasChanged) {
            console.log(`Job "${updatedJob.jobId}" updated:`, updatedJob);
            return { ...currentJob, ...updatedJob };
          }
        }
        return currentJob;
      });

      // Add any new jobs that are not in the current state
      action.jobs.forEach((job) => {
        const exists = state.jobs.some((currentJob) => currentJob.jobId === job.jobId);
        if (!exists) {
          console.log(`Adding new job from update: ${job.jobId}`);
          updatedJobs.push(job);
        }
      });

      return {
        ...state,
        jobs: updatedJobs,
        isLoading: calculateIsLoading(updatedJobs),
        debugInfo: 'Updated job statuses.',
      };

    // Handle adding a new image to the previousImages array
    case 'ADD_PREVIOUS_IMAGE':
      const updatedPreviousImages = [action.imageUrl, ...state.previousImages].slice(0, 30);
      return {
        ...state,
        previousImages: updatedPreviousImages,
        debugInfo: 'Added a new image to previousImages.',
      };

    // Handle setting previous images from user history
    case 'SET_PREVIOUS_IMAGES':
      const limitedImages = action.images.slice(-30).reverse();
      return {
        ...state,
        previousImages: limitedImages,
        debugInfo: 'Set previous images from user history.',
      };

    // Handle clearing previous images on logout
    case 'CLEAR_PREVIOUS_IMAGES':
      return {
        ...state,
        previousImages: [],
        debugInfo: 'Cleared previous images on logout.',
      };

    // Handle updating the current image URL
    case 'UPDATE_IMAGE_URL':
      return {
        ...state,
        imageUrl: action.imageUrl,
        debugInfo: 'Updated current image URL.',
      };

    // Handle errors related to specific jobs
    case 'ERROR':
      const errorJobIndex = state.jobs.findIndex((job) => job.jobId === action.jobId);
      if (errorJobIndex !== -1) {
        const updatedErrorJob = {
          ...state.jobs[errorJobIndex],
          status: JobStatus.FAILED,
          debugInfo: action.message,
        };
        const updatedJobsError = [...state.jobs];
        updatedJobsError[errorJobIndex] = updatedErrorJob;
        return {
          ...state,
          jobs: updatedJobsError,
          isLoading: calculateIsLoading(updatedJobsError),
          debugInfo: `Job "${action.jobId}" failed: ${action.message}`,
        };
      }
      console.warn(`ERROR: Job with ID ${action.jobId} not found.`);
      return state;

    // Handle unknown action types
    default:
      console.warn(`Unknown action type: ${action.type}`);
      return state;
  }
};
