import React, { useEffect } from 'react';
import styles from '../components/styles/RightPanel.module.css';

function ImageDisplay({ imageUrl }) {
  useEffect(() => {
    console.log('ImageDisplay component re-rendered with URL:', imageUrl);
  }, [imageUrl]);

  return (
    imageUrl && (
      <div className="imageDisplay">
        <img
          src={imageUrl}
          alt="Generated Output"
          className={styles.modalImage}
        />
      </div>
    )
  );
}

export default ImageDisplay;
