// src/components/RightPanel.js

import React, { useCallback, useState, useEffect } from 'react';
import ImageDisplay from './ImageDisplay';
import JobsSection from './JobsSection';
import styles from '../components/styles/RightPanel.module.css';
import { saveAs } from 'file-saver';

const useImageHandler = ({ setModalOpen, previousImages }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  const [currentImageUrl, setCurrentImageUrl] = useState(null);

  const openModal = useCallback(
    (imageUrl) => {
      const index = previousImages.findIndex((url) => url === imageUrl);
      if (index !== -1) {
        setCurrentImageIndex(index);
        setModalOpen(true);
      }
    },
    [setModalOpen, previousImages]
  );

  const closeModal = useCallback(() => {
    setModalOpen(false);
    setCurrentImageIndex(null);
    setCurrentImageUrl(null);
  }, [setModalOpen]);

  const saveImage = useCallback((imageUrl) => {
    if (imageUrl) {
      const fileName = getFileName(imageUrl);
      saveAs(imageUrl, fileName);
    }
  }, []);

  const shareImage = useCallback(async (imageUrl) => {
    if (!imageUrl) return;

    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Check out this image',
          url: imageUrl,
        });
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else if (navigator.clipboard) {
      try {
        await navigator.clipboard.writeText(imageUrl);
        alert('Image URL copied to clipboard!');
      } catch (error) {
        console.error('Failed to copy:', error);
        alert('Failed to copy the image URL.');
      }
    } else {
      const textarea = document.createElement('textarea');
      textarea.value = imageUrl;
      textarea.style.position = 'fixed';
      document.body.appendChild(textarea);
      textarea.focus();
      textarea.select();

      try {
        document.execCommand('copy');
        alert('Image URL copied to clipboard!');
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
        alert('Failed to copy the image URL.');
      }

      document.body.removeChild(textarea);
    }
  }, []);

  const getFileName = useCallback((str) => {
    return str.substring(str.lastIndexOf('/') + 1);
  }, []);

  useEffect(() => {
    if (currentImageIndex !== null && previousImages[currentImageIndex]) {
      setCurrentImageUrl(previousImages[currentImageIndex]);
    }
  }, [currentImageIndex, previousImages]);

  return { openModal, closeModal, saveImage, shareImage, currentImageUrl, setCurrentImageIndex };
};

const RightPanel = React.memo(({ outputState, dispatchOutput, jobs, modalOpen, setModalOpen }) => {
  const { openModal, closeModal, saveImage, shareImage, currentImageUrl, setCurrentImageIndex } = useImageHandler({
    setModalOpen,
    previousImages: outputState.previousImages,
  });

  const navigateImages = useCallback(
    (direction) => {
      if (outputState.previousImages.length === 0) return;

      setCurrentImageIndex((prevIndex) => {
        if (prevIndex === null) return null;
        const nextIndex =
          (prevIndex + direction + outputState.previousImages.length) %
          outputState.previousImages.length;
        return nextIndex;
      });
    },
    [outputState.previousImages.length, setCurrentImageIndex]
  );

  useEffect(() => {
    if (!modalOpen) return;

    const handleKeyDown = (event) => {
      if (event.key === 'ArrowLeft') {
        navigateImages(-1);
      } else if (event.key === 'ArrowRight') {
        navigateImages(1);
      } else if (event.key === 'Escape') {
        closeModal();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [modalOpen, navigateImages, closeModal]);

  // Debugging: Log the jobs array to verify it's being received correctly
  useEffect(() => {
    console.log('RightPanel received jobs:', jobs);
  }, [jobs]);

  return (
    <div className={styles.rightPanel}>
      {/* Modal for viewing images */}
      <div
        className={`${styles.modalOverlay} ${modalOpen ? styles.showModal : styles.hideModal}`}
        onClick={closeModal}
        style={{ display: modalOpen ? 'flex' : 'none' }}
      >
        <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
          <button className={`${styles.modalButton} ${styles.closeButton}`} onClick={closeModal}>
            Close
          </button>
          <ImageDisplay imageUrl={currentImageUrl} />
          <div className={styles.navigation}>
            <button
              className={styles.modalButton}
              onClick={() => navigateImages(-1)}
              aria-label="Previous image"
            >
              Previous
            </button>
            <button
              className={`${styles.modalButton} ${styles.saveButton}`}
              onClick={() => saveImage(currentImageUrl)}
              aria-label="Save image"
            >
              Save
            </button>
            <button
              className={`${styles.modalButton} ${styles.shareButton}`}
              onClick={() => shareImage(currentImageUrl)}
              aria-label="Share image"
            >
              Share
            </button>
            <button
              className={styles.modalButton}
              onClick={() => navigateImages(1)}
              aria-label="Next image"
            >
              Next
            </button>
          </div>
        </div>
      </div>

      {/* Jobs Section */}
      <JobsSection
        jobs={jobs}
        saveImage={saveImage}
        openModal={openModal}
      />

      {/* Previous Images Thumbnails */}
      {outputState.previousImages.length > 0 && (
        <div className={styles.thumbnails}>
          <h2>Previous Images</h2>
          <div className={styles.thumbnailContainer}>
            {outputState.previousImages.map((url, index) => (
              <img
                key={index}
                src={url}
                className={styles.thumbnail}
                alt={`Thumbnail ${index + 1}`}
                onClick={() => openModal(url)}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
});

export default RightPanel;
