import React, { useState, useEffect, useRef } from 'react';
import styles from '../components/styles/LeftPanel.module.css';  // Import the CSS module

// Custom hook for typewriter effect
function useTypewriter(text, delay, isActive) {
  const [displayedText, setDisplayedText] = useState('');
  const [isTyping, setIsTyping] = useState(true);

  useEffect(() => {
    if (isActive && isTyping && displayedText.length < text.length) {
      const timeoutId = setTimeout(() => {
        setDisplayedText(text.substring(0, displayedText.length + 1));
      }, delay);

      return () => clearTimeout(timeoutId);
    }

    // Reset the typewriter when not active
    if (!isActive) {
      setDisplayedText('');
      setIsTyping(true);
    }
  }, [displayedText, text, delay, isTyping, isActive]);

  return [displayedText, isTyping, setIsTyping];
}

function StandardInputs({ prompt, setPrompt, isLoading }) {
  const textareaRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);

  // Placeholder text for the typewriter effect
  const placeholderText = "Prompt here...";
  
  // Determine if the overlay should be active (i.e., input is empty and not focused)
  const isOverlayActive = !isFocused && prompt.trim() === '';

  // Use the typewriter effect only when the overlay is active
  const [typewriterText, isTyping, setIsTyping] = useTypewriter(placeholderText, 100, isOverlayActive);

  useEffect(() => {
    if (textareaRef.current) {
      // Automatically adjust the height of the textarea based on its content
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [prompt]);

  const handleChange = (e) => {
    setPrompt(e.target.value);
    // Overlay visibility is managed by `isOverlayActive`
  };

  const handleFocus = () => {
    setIsFocused(true);
    setIsTyping(false);  // Stop typewriter effect when focused
  };

  const handleBlur = () => {
    setIsFocused(false);
    // The typewriter effect will restart if prompt is empty
  };

  return (
    <div className={styles.typewriterContainer}>
      {/* Typewriter overlay: Visible only when input is empty and not focused */}
      {isOverlayActive && (
        <div className={styles.typewriterOverlay}>
          {typewriterText}
          {isTyping && <span className={styles.blinkingCursor}>|</span>}
        </div>
      )}
      
      {/* Textarea for user input */}
      <textarea
        ref={textareaRef}
        value={prompt}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className={`${styles.input} ${styles.textareaHiddenPlaceholder} ${isLoading ? styles.loading : ''}`}
        rows="5"
        disabled={isLoading}
        aria-label="Prompt input"
      />
    </div>
  );
}

export default StandardInputs;
